import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />

    <div id="about" className="about content">
      <div className="container">

        <div className="service row">
          <div className="section-wrapper flex-dir-col-rev">
            <div className="section-l">
              <div className="service-desc">
                <h2 className="section-head">Our <span className="pet-orange-text">Mission</span></h2>
                <div className="section-content">
                  <p>To enrich the lives of pets and their families by providing compassionate health care while ensuring a safe and nurturing environment for your pets.</p>
                </div>
              </div>
            </div>
            <div className="section-r">
              <img src="/images/ourmission.png" alt="Our Mission"></img>
            </div>
          </div>
        </div>

        <div className="service row">
          <div className="section-wrapper">
            <div className="section-l">
              <img src="/images/ourstory.png" alt="Our story"></img>
            </div>
            <div className="section-r">
              <div className="service-desc">
                <h2 className="section-head">Our <span className="pet-orange-text">Story</span></h2>
                <div className="section-content">
                  <p>
                    When thinking about the next phases of our life (batch of 2020), this thought came to our mind <b>“What is the one thing that we want in our new home?”</b>. For Shreyas, it was a pet :)
                  </p>
                  <p>
                    But taking care of a pet for Shreyas who had absolutely no idea about how to look after himself much less a pet was a daunting task indeed.
                  </p>
                  <p>
                    And that’s how Furrble came to be.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="team row">
          <div className="section-wrapper">
            <div className="section-fw">
              <h2 className="section-head">Meet the <span className="pet-orange-text">Furrblers</span></h2>
              <div className="section-content">
                <div className="team-wrapper">
                  <div className="member">
                    <a href="https://www.linkedin.com/in/shreyasbapat/">
                      <img src="/images/team/shreyas.png" alt={"Furrbler"}></img>
                    </a>
                    <div className="member-desc">
                      <div className="name">Shreyas Bapat</div>
                      <div className="por">Co-Founder</div>
                    </div>
                  </div>
                  <div className="member">
                    <a href="https://www.linkedin.com/in/shilpijain1958/">
                      <img src="/images/team/shilpi.png" alt={"Furrbler"}></img>
                    </a>
                    <div className="member-desc">
                      <div className="name">Shilpi Jain</div>
                      <div className="por">Co-Founder</div>
                    </div>
                  </div>
                  <div className="member">
                    <a href="https://www.linkedin.com/in/neelotpal-dutta/">
                      <img src="/images/team/neel.png" alt={"Furrbler"}></img>
                    </a>
                    <div className="member-desc">
                      <div className="name">Neelotpal Dutta</div>
                      <div className="por">Co-Founder</div>
                    </div>
                  </div>
                  <div className="member">
                  <a href="https://www.linkedin.com/in/diksha--jaiswal/">
                      <img src="/images/team/diksha.png" alt={"Furrbler"}></img>
                    </a>
                    <div className="member-desc">
                      <div className="name">Diksha Jaiswal</div>
                      <div className="por">Business Development &amp; Growth</div>
                    </div>
                  </div>
                  <div className="member">
                    <a href="https://www.linkedin.com/in/swastik-sinha-44a448139">
                      <img src="/images/team/swastik.png" alt={"Furrbler"}></img>
                    </a>
                    <div className="member-desc">
                      <div className="name">Swastik Sinha</div>
                      <div className="por">Product Designer</div>
                    </div>
                  </div>
                  {/* <div className="member">
                    <a href="https://www.linkedin.com/in/akshita-jain02/">
                      <img src="/images/team/akshita.png" alt={"Furrbler"}></img>
                    </a>
                    <div className="member-desc">
                      <div className="name">Akshita Jain</div>
                      <div className="por">Product Manager Intern</div>
                    </div>
                  </div> */}
                  <div className="member">
                    <a href="https://www.linkedin.com/in/yashika-baan-39504216b/">
                      <img src="/images/team/yashika.png" alt={"Furrbler"}></img>
                    </a>
                    <div className="member-desc">
                      <div className="name">Yashika Baan</div>
                      <div className="por">Software Engineer Intern</div>
                    </div>
                  </div>
                  <div className="member">
                    <a href="https://www.linkedin.com/in/akshat-malviya/">
                      <img src="/images/team/akshat.png" alt={"Furrbler"}></img>
                    </a>
                    <div className="member-desc">
                      <div className="name">Akshat Malviya</div>
                      <div className="por">Software Engineer Intern</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </Layout>
)

export default AboutPage
